<template>
  <!-- 菜单管理 -->
  <div class="card">
    
    <div class="menu flex jc_s"> 
        <a href="/menuUser" class="flex jc_s"
            ><i class="iconfont iconmaijia_wode"></i>用户</a
        >
        <a href="/menuAuth" class="flex jc_s now"
            ><i class="iconfont icontuiguang-1"></i>菜单</a
        >
    </div>


    <div class="pb16 pt16 ai_c flex jc_end">
      <el-button type="primary" class="ssbtn" style="width: 6.75rem" @click="addBtn">添加菜单</el-button>
      <div class="flex jc_end f14 col333 ai_c"></div>
    </div>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="valtable1" label="菜单名称" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.menu_name }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="cname" label="菜单路径" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.menu_addr }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="cname" label="菜单图标" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.menu_icon }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="auth" label="默认权限" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.auth == 0">查看</div>
            <div v-if="scope.row.auth == 1">编辑</div>
          </template>
        </el-table-column>
        <el-table-column prop="in20" label="排序" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.sort }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="out90" label="创建时间">
          <template slot-scope="scope">
            <div>{{ scope.row.created_at }}</div>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="240">
          <template slot-scope="scope">
            <el-button class="qxbtn mr10" @click="editBtn(scope.row)">编辑</el-button>
            <el-button class="delbtn"  @click="delBtn(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加编辑弹窗 -->
    <el-dialog :title="type_title" :show-close="false" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="500px">
      <el-form :model="data_obj" :rules="rules" ref="ruleForm">
        <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.menu_name" autocomplete="off" placeholder="菜单名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.menu_addr" autocomplete="off" placeholder="菜单路径"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.menu_icon" autocomplete="off" placeholder="菜单图标"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限" :label-width="formLabelWidth" prop="">
          <el-select filterable clearable class="el_ipt" v-model="data_obj.auth" placeholder="类型">
            <el-option label="查看" value="0"></el-option>
            <el-option label="编辑" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" :label-width="formLabelWidth" prop="">
          <el-input class="el_ipt" v-model="data_obj.sort" autocomplete="off" placeholder="排序"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="sureadd('ruleForm')" :loading="loading">提 交</el-button>
      </div>
    </el-dialog>

     <div class="page pt16">
        <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="total"> </el-pagination>
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      tableData: [],
      data_obj: {
        menu_name: '',
        menu_addr: '',
        menu_icon: '',
        auth: '',
        sort: '',
      },

      formLabelWidth: '80px',
      rules: {
        menu: [{ required: true, message: '菜单名', trigger: 'blur' }],
        index: [{ required: true, message: '节点', trigger: 'blur' }],
        type: [{ required: true, message: '类型', trigger: 'blur' }],
        parentid_list: [{ required: true, message: '上级菜单', trigger: 'blur' }],
      },
      loading: false,

      //   type: 'add',
      type_title: '添加菜单',
      dialogFormVisible: false,
      pageSize: 10,
      currentPage: 1,
      total: 0,
    }
  },
  watch: {},
  computed: {},
  methods: {
    addBtn() {
      // this.type = "add";
      this.data_obj = {}
      this.type_title = '添加菜单'
      this.dialogFormVisible = true
    },
    editBtn(row) {
      this.data_obj = {}
      this.type_title = '编辑菜单'
      this.dialogFormVisible = true
      this.data_obj = {
        ...row,
        id: row.id,
        auth: String(row.auth)
      }
    },
    delBtn() {},
    sureadd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const that = this
          let data = {}
          if (this.type_title == '编辑菜单') {
            data = {
              id:that.data_obj.id,
              menu_name: that.data_obj.menu_name,
              menu_addr: that.data_obj.menu_addr,
              menu_icon: that.data_obj.menu_icon,
              auth: that.data_obj.auth,
              sort: that.data_obj.sort,
            }
          }else{
            data = {
              menu_name: that.data_obj.menu_name,
              menu_addr: that.data_obj.menu_addr,
              menu_icon: that.data_obj.menu_icon,
              auth: that.data_obj.auth,
              sort: that.data_obj.sort,
            }
          }
          this.$api.setAuth(data).then((res) => {
            console.log('res', res)
            if (res.data.code == 0) {
              that.$message.success('成功')
              setTimeout(function () {
                that.dialogFormVisible = false
                that.getData();
              }, 100)
            }
          })
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData(val)
    },
    filter(val) {
      let offset = (val - 1) * this.pageSize
      let data = {
        limit: this.pageSize,
        offset: offset,
      }
      return data
    }, //filter
    getData(val = 1) {
      const that = this
      let data = this.filter(val)
      // 
      this.$api.getAuth(data).then((res) => {
        console.log('res', res)
        if (res.data.code == 0) {
          that.tableData = res.data.data.rows
          that.total = res.data.data.count
        }
      })
    },
    handleCancle() {
      this.dialogFormVisible = false
      this.data_obj = {}
    },
  },
  created() {
    this.getData()
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
</style>